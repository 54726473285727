<template>
  <div @click="viewSession" :role="sessionIsEmpty ? '' : 'button'" class="card">
    <div class="card-body">
      <div class="card-title d-flex align-items-center">
        <h5 class="m-0 me-2 flex-grow-1">{{ this.session | sessionNameReadable }}</h5>
        <SessionTimer
          v-if="sessionIsStarted && !sessionIsFinished"
          :startTime="this.session.startedAt"
          class="badge rounded-pill bg-danger"
        ></SessionTimer>
        <ExerciseStatus v-else-if="sessionIsFinished" :isCompleted="true"></ExerciseStatus>
        <ExerciseStatus
          v-else-if="sessionIsInPast && !sessionIsFinished"
          :isCompleted="false"
        ></ExerciseStatus>
      </div>

      <div v-if="sessionIsEmpty">No exercises</div>

      <div v-else class="list-group list-group-flush">
        <div
          v-for="exercise in session.exercises"
          :key="`key-${exercise.exerciseId}`"
          class="list-group-item d-flex align-items-baseline"
        >
          <ExerciseBadge
            v-if="exercise.type !== 'section'"
            :exercise="exercise"
            :exercises="session.exercises"
            :variant="'textColor'"
            class="me-2"
          ></ExerciseBadge>

          <div
            :class="exercise.type === 'section' ? 'fw-bold' : ''"
            class="flex-grow-1"
          >{{ exercise.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

export default {
  name: 'SessionOverview',
  components: {
    SessionTimer: () => import('@/components/SessionTimer'),
    ExerciseBadge: () => import('@/components/ExerciseBadge'),
    ExerciseStatus: () => import('@/components/ExerciseStatus'),
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sessionIsEmpty() {
      const exercises = this.session.exercises.filter((exercise) => exercise.type !== 'section');
      return !exercises.length;
    },
    sessionIsStarted() {
      const { startedAt, finishedAt } = this.session;
      return startedAt !== null && finishedAt === null;
    },
    sessionIsFinished() {
      const { startedAt, finishedAt } = this.session;
      return startedAt !== null && finishedAt !== null;
    },
    sessionIsInPast() {
      const today = dayjs().subtract(1, 'day');
      const sessionDate = dayjs(this.session.date);
      return sessionDate.isSameOrBefore(today);
    },
  },
  methods: {
    viewSession() {
      if (!this.sessionIsEmpty) {
        const { session: { sessionId } } = this;
        this.$router.push(`/workout/${sessionId}`);
      }
    },
  },
};
</script>
